<template>
  <a-row v-if="isLoading" class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>
          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="3" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="3" class="form_title"> Название: </a-col>
            <a-col :span="15" class="sm-mb">
              <a-input
                v-model="form['title_' + getKey]"
                class="form_title-input"
                placeholder="Введите название"
              />
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="3" class="form_title"> Ссылка: </a-col>
            <a-col :span="6" class="sm-mb">
              <a-input
                v-model="form.link"
                placeholder="Введите ссылку (Обязательно http:// или https:// )"
              />
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="3" class="form_title"> Изображение: </a-col>
            <a-col :span="12" class="sm-mb">
              <a-button
                style="font-size: 13px"
                type="primary"
                @click="uploadFile"
              >
                <a-icon style="font-size: 16px" type="upload" /> Upload Image
              </a-button>
              <span v-if="!image_url" style="padding-left: 8px">
                Картинка не выбрана
              </span>
              <input
                ref="fileInput"
                style="display: none"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/svg"
                @change="handleFileUpload"
              />
              <div class="committee-image">
                <img :src="image_url" />
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="3" />
            <a-col :span="12">
              <a-button style="margin-right: 10px" @click="$router.go(-1)">
                <a-icon type="close" />Отмена
              </a-button>
              <a-button
                :loading="pending"
                type="primary"
                @click="submitUsefullLinks"
              >
                Сохранить
              </a-button>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
export default {
  components: {},
  data: () => {
    return {
      form: {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: "",
        link: "",
        type: 2,
        photo: null,
        logo: null
      },
      fileList: [],
      langTab: "oz",
      image: null,
      pending: false,
      image_url: "",
      isLoading: false
    }
  },
  computed: {
    getKey() {
      return this.langTab
    },
    getUrl() {
      return this.form.link
    }
  },
  // watch: {
  //   getUrl(val) {
  //     console.log("form.url", val)
  //   }
  // },
  async created() {
    this.isLoading = false
    let id = this.$route.params.id
    const res = await this.$api.get(`/admin/common/usefullink/${id}/update/`)
    const form = res.data
    console.log(form)
    this.form = await { ...form, link: form.link }
    this.image_url = await form.logo
    this.isLoading = true
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click()
    },
    async submitUsefullLinks() {
      this.pending = true
      const id = this.$route.params.id
      try {
        let form = this.form
        form = {
          ...form
        }
        delete form.images
        delete form.slug
        delete form.content_uz
        delete form.content_oz
        delete form.content_ru
        delete form.content_en
        delete form.content_qr
        delete form.group
        if (typeof form.logo === "string") {
          delete form.logo
        }
        let f = new FormData()
        Object.keys(form).forEach((key) => {
          f.append(key, form[key])
        })
        this.$api.patch(`/admin/common/usefullink/${id}/update/`, f)
        this.$message.success("Полезная ссылка изменена успешно!")
        this.$router.go(-1)
      } catch (error) {
        console.error(error)
        this.$sentry.captureMessage(error)
      }

      this.pending = false
    },
    handleImages(arg) {
      this.fileList = arg
    },
    async removeItem() {
      await this.remove(this.data.id)
      this.$message.success("Полезная ссылка успешно удалена")
      this.$router.push({ name: "usefullLinks" })
    },
    handleFileUpload(e) {
      // this.form.photo = this.$refs.file.files[0]
      console.log(e.target.files[0], "dfhdjfhjdfhdjfdfhd")
      this.image_url = URL.createObjectURL(e.target.files[0])
      this.form.logo = e.target.files[0]
    }
  }
}
</script>

<style scoped>
.committee-image {
  overflow: hidden;
  margin-top: 15px;
}
.committee-image > img {
  height: 200px;
}
</style>
